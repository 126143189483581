@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide scrollbars */
.scrollbar-hide {
  /* For WebKit-based browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    display: none;
  }
  /* For Firefox */
  scrollbar-width: none;
  /* For Internet Explorer 10+ */
  -ms-overflow-style: none;
}